import { useCallback } from 'react'

import useCvip from './useCvip'
import { useWallet } from 'use-wallet'

import { stake, getMasterChefContract } from '../cvip/utils'

const useStake = (pid: number) => {
  const { account } = useWallet()
  const cvip = useCvip()

  const handleStake = useCallback(
    async (amount: string) => {
      const txHash = await stake(
        getMasterChefContract(cvip),
        pid,
        amount,
        account,
      )
      console.log(txHash)
    },
    [account, pid, cvip],
  )

  return { onStake: handleStake }
}

export default useStake
