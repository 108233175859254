import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { UseWalletProvider } from 'use-wallet'
// import DisclaimerModal from './components/DisclaimerModal'
import MobileMenu from './components/MobileMenu'
import TopBar from './components/TopBar'
import TopBar2 from './components/TopBar2'
import FarmsProvider from './contexts/Farms'
import ModalsProvider from './contexts/Modals'
import TransactionProvider from './contexts/Transactions'
import CvipProvider from './contexts/CvipProvider'
import useModal from './hooks/useModal'
import theme from './theme'
import Farms from './views/Farms'
import Home from './views/Home'
import Staking from "./views/Staking";

const App: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(false)

  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false)
  }, [setMobileMenu])

  const handlePresentMobileMenu = useCallback(() => {
    setMobileMenu(true)
  }, [setMobileMenu])

  return (
    <Providers>
      <Router>
        <TopBar2 />
        {/* <TopBar onPresentMobileMenu={handlePresentMobileMenu} /> */}
        <MobileMenu onDismiss={handleDismissMobileMenu} visible={mobileMenu} />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/farms">
            <Farms />
          </Route>
          <Route path="/staking">
            <Staking />
          </Route>
        </Switch>
      </Router>
      {/* <Disclaimer /> */}
    </Providers>
  )
}

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <UseWalletProvider
        chainId={56}
        connectors={{
          walletconnect: { rpcUrl: 'https://bsc-dataseed.binance.org/' }
        }}
      >
        <CvipProvider>
          <TransactionProvider>
            <FarmsProvider>
              <ModalsProvider>{children}</ModalsProvider>
            </FarmsProvider>
          </TransactionProvider>
        </CvipProvider>
      </UseWalletProvider>
    </ThemeProvider>
  )
}

// const Disclaimer: React.FC = () => {
//   const markSeen = useCallback(() => {
//     localStorage.setItem('disclaimer', 'seen')
//   }, [])

//   const [onPresentDisclaimerModal] = useModal(
//     <DisclaimerModal onConfirm={markSeen} />,
//   )

//   useEffect(() => {
//     const seenDisclaimer = false //localStorage.getItem('disclaimer')
//     if (!seenDisclaimer) {
//       onPresentDisclaimerModal()
//     }
//   }, [])

//   return <div />
// }

export default App
