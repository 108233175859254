import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useWallet} from 'use-wallet'
import {provider} from 'web3-core'
import Spacer from '../../components/Spacer'
import useCvip from '../../hooks/useCvip'
import {getContract} from '../../utils/erc20'
import UnstakeXCvip from './components/UnstakeXCvip'
import StakeCvip from "./components/StakeCvip";

import {contractAddresses} from '../../cvip/lib/constants'
import {getXCvipSupply} from "../../cvip/utils";
import BigNumber from "bignumber.js";
import {getBalanceNumber} from "../../utils/formatBalance";

const StakeXCvip: React.FC = () => {
  const {
    tokenAddress,
  } = {
    tokenAddress: contractAddresses.xCvip[56],
  }

  const [totalSupply, setTotalSupply] = useState<BigNumber>()

  const cvip = useCvip()
  const {ethereum} = useWallet()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    async function fetchTotalSupply() {
      const supply = await getXCvipSupply(cvip)
      setTotalSupply(supply)
    }
    if (cvip) {
      fetchTotalSupply()
    }
  }, [cvip, setTotalSupply])



  const lpContract = useMemo(() => {
    debugger
    return getContract(ethereum as provider, tokenAddress)
  }, [ethereum, tokenAddress])

  return (
    <>
      <StyledFarm>
        <StyledCardsWrapper>
          <StyledCardWrapper>
            <UnstakeXCvip
              lpContract={lpContract}
            />
          </StyledCardWrapper>
          <Spacer/>
          <StyledCardWrapper>
            <StakeCvip
            />
          </StyledCardWrapper>
        </StyledCardsWrapper>
        <Spacer size="lg"/>
        <StyledCardsWrapper>
          <StyledCardWrapper>
            <StyledInfo>
              ℹ️️ You will earn a portion of the swaps fees based on the amount
              of xCvip held relative the weight of the staking. xCvip can be minted
              by staking Cvip. To redeem Cvip staked plus swap fees convert xCvip
              back to Cvip. {totalSupply ? `There are currently ${getBalanceNumber(totalSupply)} xCVIP in the whole pool.` : '' }
            </StyledInfo>
          </StyledCardWrapper>
        </StyledCardsWrapper>
        <Spacer size="lg"/>
      </StyledFarm>
    </>
  )
}

const StyledFarm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 600px;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.grey[400]};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`

export default StakeXCvip
