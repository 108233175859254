import React, { createContext, useEffect, useState } from 'react'

import { useWallet } from 'use-wallet'

import { Cvip } from '../../cvip'

export interface CvipContext {
  cvip?: typeof Cvip
}

export const Context = createContext<CvipContext>({
  cvip: undefined,
})

declare global {
  interface Window {
    cvipsauce: any
  }
}

const CvipProvider: React.FC = ({ children }) => {
  const { ethereum }: { ethereum: any } = useWallet()
  const [cvip, setCvip] = useState<any>()

  // @ts-ignore
  window.cvip = cvip
  // @ts-ignore


  useEffect(() => {
    if (ethereum) {
      const chainId = Number(ethereum.chainId)
      const cvipLib = new Cvip(ethereum, chainId, false, {
        defaultAccount: ethereum.selectedAddress,
        defaultConfirmations: 1,
        autoGasMultiplier: 1.5,
        testing: false,
        defaultGas: '6000000',
        defaultGasPrice: '1000000000000',
        accounts: [],
        ethereumNodeTimeout: 10000,
      })
      setCvip(cvipLib)
      window.cvipsauce = cvipLib
    }
  }, [ethereum])

  return <Context.Provider value={{ cvip }}>{children}</Context.Provider>
}

export default CvipProvider
